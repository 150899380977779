/* src/App.css */

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  padding: 50px;
}

.logo {
  width: 350px;
  height: auto;
  margin-bottom: 20px;
}

form {
  margin: 20px 0;
}

input[type="text"] {
  padding: 10px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}

button:hover {
  background-color: #0056b3;
}

.results {
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.results h2 {
  color: #333;
}

.formatted-results {
  text-align: left;
  margin: 0 auto;
  max-width: 600px;
}

.formatted-results p {
  color: #666;
  margin: 10px 0;
  line-height: 1.5;
}


@media (max-width: 768px) {
  .button {
      font-size: 16px;
      padding: 10px;
  }

  input[type="text"] {
      font-size: 14px;
      padding: 8px;
  }
 .textinput{
  margin: 1rem;
 } 
}